{
  const vidsCantPlayInline =
    navigator.userAgent.match(/(iPhone|iPod)/g) &&
    !('playsInline' in document.createElement('video'));

  if (vidsCantPlayInline) {
    $('.ui-intro .placeholder').style.display = 'none';
    $('.topbar').style.display = 'none';
    $('.ui-intro article').style.marginTop = '30px';
  }

  const ieLTE11 =
    navigator.userAgent.indexOf('MSIE') !== -1 ||
    navigator.appVersion.indexOf('Trident/') > 0;
  if (ieLTE11)
    [...$$('.simple-features > div > div')].forEach(el =>
      el.parentNode.removeChild(el),
    );

  if (window.Vimeo) {
    var player = new Vimeo.Player(document.getElementById('marketingVideo'));
    player.on('play', function() {
      ga('send', 'event', 'Video', 'play');
    });
    player.on('ended', function() {
      ga('send', 'event', 'Video', 'ended');
    });
    player.on('seeked', function(e) {
      ga('send', 'event', 'Video', 'seeked', null, e.seconds);
    });
  }

  const vidButtons = $$('.scroll-and-play-video');
  vidButtons.forEach(button => {
    button.addEventListener('click', e => {
      e.preventDefault();
      if (window.Vimeo) player.play();
      location.hash = '#';
      location.hash = '#marketingVideoContainer';
    });
  });

  function positionMenuItem() {
    if (onMobile()) {
      $('.videos').style.left = `${$('#pingy-menubar').offsetLeft - 193}px`;
    } else {
      $('.videos').style.left = 'auto';
    }
  }
  positionMenuItem();

  const topbar = $('.topbar');
  const playFromStart = el => {
    el.currentTime = 0;
    if (el.paused) {
      el.play();
    }
  };
  const hideAllVideos = except =>
    $$('.videos video').forEach(el => {
      // if (vidsCantPlayInline) return;
      if (el.className === except) {
        el.style.display = 'block';
        playFromStart(el);
        el.addEventListener(
          'ended',
          () => {
            setTimeout(() => {
              if (
                el.style.display === 'block' && el.currentTime === el.duration
              ) {
                playFromStart(el);
              }
            }, 4500);
          },
          false,
        );
      } else {
        el.style.display = 'none';
      }
    });

  const makeSectionActive = except =>
    $$('body > section').forEach(el => {
      if (el.classList.contains(except)) {
        el.classList.add('active');
      } else {
        el.classList.remove('active');
      }
    });

  const menubar = {
    state: 'invisible',
    fromInvisibleToStatic() {
      this.state = 'static';
      utils.addClassTransition(topbar, 'active');
      $('.videos').style.display = 'block';
    },
    fromInvisibleToFixed() {
      this.fromInvisibleToStatic();
      this.fromStaticToFixed();
    },
    fromStaticToFixed() {
      this.state = 'fixed';
      utils.removeClassTransition(topbar, 'active');
      const bounds = $('.topbar').getBoundingClientRect();
      // $('.videos').style.position = 'absolute';
      // $('.videos').style.right = 'auto';
      // $('.videos').style.left = `${bounds.right - $('.videos').offsetWidth - 13}px`;
      $('.pseudo-pingy-menubar').classList.add('active');
      $(
        '.pseudo-pingy-menubar',
      ).style.left = `${bounds.right - $('.videos').offsetWidth / 2 - 63}px`;
    },
    fromFixedToStatic() {
      this.state = 'static';
      utils.addClassTransition(topbar, 'active');
      // $('.videos').style.position = 'absolute';
      // $('.videos').style.right = '13px';
      // $('.videos').style.top = '-24px';
      // $('.videos').style.left = 'auto';
      $('.videos').style.transform = '';
      $('.pseudo-pingy-menubar').classList.remove('active');
      $('.pseudo-pingy-menubar').style.transform = `translateY(0)`;
    },
  };
  const dos = {
    hero() {},
    ui() {
      makeSectionActive('ui-intro');
      hideAllVideos('pingy-ui-video');

      if (onMobile()) return;
      if (menubar.state === 'invisible') {
        menubar.fromInvisibleToStatic();
      } else {
        menubar.fromFixedToStatic();
      }
    },
    live() {
      makeSectionActive('live-share');
      if (onMobile()) return;
      if (menubar.state === 'invisible') {
        menubar.fromInvisibleToFixed();
      } else {
        menubar.fromStaticToFixed();
      }
      hideAllVideos('pingy-liveshare-video');
      // $('.pseudo-pingy-menubar .highlight').style.backgroundColor = 'hsl(60, 78%, 44%)';
      const offsetDiff = $('.live-share').offsetTop - $('.ui-intro').offsetTop;
      $('.videos').style.transform = `translateY(${offsetDiff}px)`;
      $(
        '.pseudo-pingy-menubar',
      ).style.transform = `translateY(${offsetDiff}px)`;
    },
    deploy() {
      makeSectionActive('deploy');
      if (onMobile()) return;
      if (menubar.state === 'invisible') {
        menubar.fromInvisibleToFixed();
      } else {
        menubar.fromStaticToFixed();
      }

      hideAllVideos('pingy-deploy-video');
      // $('.pseudo-pingy-menubar .highlight').style.backgroundColor = 'hsl(120, 47%, 58%)';
      const offsetDiff = $('.deploy').offsetTop - $('.ui-intro').offsetTop;
      $('.videos').style.transform = `translateY(${offsetDiff}px)`;
      $(
        '.pseudo-pingy-menubar',
      ).style.transform = `translateY(${offsetDiff}px)`;
    },
    config() {
      makeSectionActive('config');
    },
    overview() {
      makeSectionActive('overview');
    },
  };

  const sectionOffsets = {};
  let sectionOffsetsArrays;

  function calcSectionOffsets() {
    sectionOffsets.hero = $('section.hero').offsetTop;
    sectionOffsets.ui = $('section.ui-intro').offsetTop;
    sectionOffsets.live = $('section.live-share').offsetTop;
    sectionOffsets.deploy = $('section.deploy').offsetTop;
    sectionOffsets.overview = $('section.overview').offsetTop;
    sectionOffsets.config = $('section.config').offsetTop;

    sectionOffsetsArrays = utils.splitObj(sectionOffsets);
  }
  calcSectionOffsets();

  window.addEventListener('resize', () => {
    positionMenuItem();
    calcSectionOffsets();
    onScroll(true);
  });
  window.addEventListener('load', calcSectionOffsets);

  let currentScrollState = null;
  let lastScrollState = null;
  let activatedState = '';

  function onScroll(force) {
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    const middleScrollPos = scrollY + window.innerHeight / 2;
    const closestSectionPx = [...sectionOffsetsArrays.vals]
      .reverse()
      .find(offset => middleScrollPos >= offset);
    // console.log(closestSectionPx, window.scrollY);

    for (const name in sectionOffsets) {
      const px = sectionOffsets[name];
      if (
        closestSectionPx === px && (force === true || activatedState !== name)
      ) {
        dos[name]();
        activatedState = name;
      }
    }
  }
  const debouncedOnScroll = utils.debounce(onScroll, 10);
  onScroll();
  window.addEventListener('scroll', debouncedOnScroll);

  let imageEnlarged = false;
  function configImageToggle(e) {
    if (imageEnlarged) {
      document.body.classList.remove('faded');
    } else {
      $('#config-image-fullscreen').src = '/images/config-screenshot.png';
      document.body.classList.add('faded');
    }
    imageEnlarged = !imageEnlarged;
  }

  if (!onMobile()) {
    $('#config-image').addEventListener('click', configImageToggle);
    $('.fullscreen-fade').addEventListener('click', configImageToggle);
    $('.fullscreen-fade').addEventListener(
      'touchmove',
      e => e.preventDefault(),
      false,
    );
  }
}
